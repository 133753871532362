<template>
  <el-main>
    <div style="background:#fff;">
      <el-tabs type="border-card" v-model="activeName" @tab-click="setTabChange">
        <el-tab-pane label="基本设置" name="setting">
          <el-form :inline="true" :model="userformdata" ref="userform" :show-message="false">
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('setting.ip_filter')" label-width="150px" prop="ip_filter">
                <el-radio-group v-model="userformdata.ip_filter">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.auto_audio')" label-width="150px" prop="auto_audio">
                <el-radio-group v-model="userformdata.auto_audio">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="userformdata.country != 57">
            <el-col :span="12">
              <el-form-item :label="$t('setting.first_cash_min_money')" label-width="150px"
                            prop="first_cash_min_money">
                <el-input type="number" v-model="userformdata.first_cash_min_money" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.first_cash_max_money')" label-width="150px"
                            prop="first_cash_max_money">
                <el-input type="number" v-model="userformdata.first_cash_max_money" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="userformdata.country != 57">
            <el-col :span="12">
              <el-form-item :label="$t('setting.cash_min_money')" label-width="150px" prop="cash_min_money">
                <el-input type="number" v-model="userformdata.cash_min_money" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.cash_max_money')" label-width="150px" prop="cash_max_money">
                <el-input type="number" v-model="userformdata.cash_max_money" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="userformdata.country == 57">
            <el-col :span="24">
              <el-row v-for="(withd,idx) in withds" :key="idx">
                <el-col :span="12">
                  <el-form-item :label="$t(withd.title+'最低金额')" label-width="150px"
                                prop="minprice">
                    <el-input type="number" v-model="withd.minprice" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t(withd.title+'最高金额')" label-width="150px"
                                prop="maxprice">
                    <el-input type="number" v-model="withd.maxprice" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('setting.cash_times')" label-width="150px" prop="cash_times">
                <el-input type="number" v-model="userformdata.cash_times" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.withd_fee')" label-width="150px" prop="withd_fee">
                <el-radio-group v-model="userformdata.withd_fee">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('setting.cash_service_charge')" label-width="150px" prop="cash_service_charge">
                <el-input type="number" v-model="userformdata.cash_service_charge" autocomplete="off">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.three_withd_fee')" label-width="150px" prop="three_withd_fee">
                <el-input type="number" v-model="userformdata.three_withd_fee" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('交保险税收')" label-width="150px" prop="bx_tax_pc">
                <el-input type="number" v-model="userformdata.bx_tax_pc" autocomplete="off">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('未交税收')" label-width="150px" prop="nbx_tx_pc">
                <el-input type="number" v-model="userformdata.nbx_tax_pc" autocomplete="off">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('setting.bank_bind_acc')" label-width="150px" prop="bank_bind_acc">
                <el-input type="number" v-model="userformdata.bank_bind_acc" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.withd_limit')" label-width="150px" prop="withd_limit">
                <el-radio-group v-model="userformdata.withd_limit">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('setting.refuse_time')" label-width="150px" prop="cash_times">
                <el-input type="number" v-model="userformdata.refuse_time" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.min_recharge')" label-width="150px" prop="min_recharge">
                <el-input type="number" v-model="userformdata.min_recharge" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('setting.id_limit')" label-width="150px" prop="id_limit" style="width:100%;">
                <el-input type="textarea" placeholder="用英文,号隔开" v-model="userformdata.id_limit" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('setting.giftprice')" label-width="150px" prop="giftprice">
                <el-input :placeholder="$t('setting.giftprice')" v-model="userformdata.giftprice" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="审核时间间隔" label-width="150px" prop="audit_time" style="width:100%;">
                <el-input type="number" placeholder="审核时间间隔" v-model="userformdata.audit_time" autocomplete="off">
                  <template #append>分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提现免手续费" label-width="150px" prop="audit_time" style="width:100%;">
                <el-input type="number" placeholder="提现免手续费金额" v-model="userformdata.min_free_withd" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('周末提现')" label-width="150px" prop="weekwitdh">
                <el-radio-group v-model="userformdata.weekwitdh">
                  <el-radio :label="1">允许</el-radio>
                  <el-radio :label="0">不允许</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
            {{ $t('global.submit') }}
          </el-button>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="客服信息设置" class="sertab" name="sertab">
          <el-form ref="serform" :model="serdata" label-width="120px">
            <el-form-item label="语言">
              <el-select v-model="serdata.lang" placeholder="语言" @change="getserinfo">
                <el-option v-for="lang in langlist"
                           :key="lang.id"
                           :label="lang.name"
                           :value="lang.lang">
                  {{lang.name}}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="欢迎文案">
              <el-input type="textarea" v-model="serdata.welcome"></el-input>
            </el-form-item>
            <el-form-item label="客服标题文案">
              <el-input type="text" v-model="serdata.catetitle"></el-input>
            </el-form-item>
            <el-form-item label="在线客服文案">
              <el-input type="text" v-model="serdata.online_text"></el-input>
            </el-form-item>
            <el-form-item label="Line客服文案">
              <el-input type="text" v-model="serdata.line_text"></el-input>
            </el-form-item>
            <el-form-item label="Line客服号码">
              <el-input type="text" v-model="serdata.line_num"></el-input>
            </el-form-item>
            <el-form-item label="VIP客服文案">
              <el-input type="text" v-model="serdata.vip_text"></el-input>
            </el-form-item>
            <el-form-item label="提现Line账号">
              <el-input type="text" v-model="serdata.line_attr" placeholder="多个账号用,号隔开" @input="changeLineAttr"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="setservices('serform')">确定保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="普通用户卡提现设置" class="sertab" name="overat">
          <el-form ref="overatform" :model="syssetting" label-width="120px" style="width: 100%;">
            <el-form-item label="英文" style="width: 100%;">
              <el-input type="textarea" v-model="syssetting.overat_en_US"></el-input>
            </el-form-item>
            <el-form-item label="塞尔维亚" style="width: 100%;">
              <el-input type="textarea" v-model="syssetting.overat_Serbia"></el-input>
            </el-form-item>
            <el-form-item label="续加时间:" style="width: 100%;">
              <el-input type="text" v-model="syssetting.overdate" class="iw-md">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="savesyssetting('overatform')">确定保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="收付款银行卡设置" class="sertab" name="obank">
          <el-form ref="obankform" :model="obankdata" label-width="120px" style="width: 100%;">
            <div style="margin-bottom:20px; color:#999; padding:5px 10px 6px; background:#f1f1f1;">以下是出款银行卡配置</div>
            <el-form-item label="姓名" style="width: 100%;">
              <el-input v-model="obankdata.name"></el-input>
            </el-form-item>
            <el-form-item label="街道" style="width: 100%;">
              <el-input v-model="obankdata.street"></el-input>
            </el-form-item>
            <el-form-item label="城市" style="width: 100%;">
              <el-input v-model="obankdata.city"></el-input>
            </el-form-item>
            <el-form-item label="银行账号" style="width: 100%;">
              <el-input v-model="obankdata.accNumber"></el-input>
            </el-form-item>
            <el-form-item label="目的" style="width: 100%;">
              <el-input v-model="obankdata.bpurpose"></el-input>
            </el-form-item>
            <el-form-item label="目的编号" style="width: 100%;">
              <el-input v-model="obankdata.purposeCode"></el-input>
            </el-form-item>
            <div style="margin-bottom:20px; color:#999; padding:5px 10px 6px; background:#f1f1f1;">以下是入款银行卡配置</div>
            <el-form-item label="姓名" style="width: 100%;">
              <el-input v-model="obankdata.outname"></el-input>
            </el-form-item>
            <el-form-item label="街道" style="width: 100%;">
              <el-input v-model="obankdata.outstreet"></el-input>
            </el-form-item>
            <el-form-item label="城市" style="width: 100%;">
              <el-input v-model="obankdata.outcity"></el-input>
            </el-form-item>
            <el-form-item label="银行账号" style="width: 100%;">
              <el-input v-model="obankdata.outaccNumber"></el-input>
            </el-form-item>
            <el-form-item label="目的编号" style="width: 100%;">
              <el-input v-model="obankdata.purpose"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveobankform('obankform')">确定保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>

<script>
// @ is an alias to /src

import {httpget, httppost} from "../../libs/api";

export default {
  name: 'setting',
  components: {},
  data() {
    return {
      activeName: 'setting',
      userformdata: {},
      searchinfo: '',
      uploadurl: process.env.VUE_APP_IMG_DOMAIN + '/system/upload/images?storage=signimg',
      uploadheader: {Authorization: this.$store.getters.getToken},
      isbusy: false,
      serdata: {},
      defaultserdata: {
        lang: 'en_US',
        welcome: '',
        catetitle: '',
        online_text: '',
        line_text: '',
        vip_text: '',
        line_num: '',
        refuse_time:'',
      },
      syssetting: {
        overat_en_US: '',
        overat_Serbia: '',
        overdate: 10,
      },
      obankdata:{
        name:'',
        street:'',
        city:'',
        accNumber:'',
        bpurpose:'',
        purposeCode:'',
        outname:'',
        outstreet:'',
        outcity:'',
        outaccNumber:'',
        purpose:'',
      },
      withds:[
        {
          title:'第一次提现',
          times:1,
          minprice:'0.00',
          maxprice:'0.00'
        },
        {
          title:'第二次提现',
          times:2,
          minprice:'0.00',
          maxprice:'0.00'
        },
        {
          title:'第三次提现',
          times:3,
          minprice:'0.00',
          maxprice:'0.00'
        },
        {
          title:'第四次提现',
          times:4,
          minprice:'0.00',
          maxprice:'0.00'
        },
        {
          title:'之后提现',
          times:5,
          minprice:'0.00',
          maxprice:'0.00'
        },
      ]
    }
  },
  methods: {
    async setTabChange(tab, event) {
      if(this.activeName=='overat'){
        this.getSyssetting();
      }
    },
    async getSyssetting(){
      let res = await httpget('/system/setting/sysindex');
      if(res.code==0){
        this.syssetting=res.data;
      }
    },
    async savesyssetting(){
      let res = await httppost('/system/setting/sysindex', this.syssetting);
      if(res.code==0){
        this.$message({type: 'success', message: res.msg});
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    async saveobankform(){
      let RegExp = /^[0-9]{3,3}-[0-9]{13,13}-[0-9]{2,2}$/;
      if( (this.obankdata.accNumber && !RegExp.test(this.obankdata.accNumber)) || (this.obankdata.outaccNumber && !RegExp.test(this.obankdata.outaccNumber)) ){
        this.$message({type: 'error', message: '银行卡号格式不正确'});
        return;
      }
      this.isbusy = true;
      this.obankdata.id = this.userformdata.id;
      let res = await httppost('/system/setting/save', this.obankdata);
      if(res.code==0){
        this.$message({type: 'success', message: res.msg});
        this.isshowdialog = false;
        this.isbusy = false;
      }else{
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getlist() {
      this.isbusy = true;
      let res = await this.httpget('/system/setting/index', this.searchinfo);
      if (res.code === 0) {
        this.userformdata = res.data;
        this.withds = res.data.withds;
        this.obankdata = {
              name:res.data.name,
              street:res.data.street,
              city:res.data.city,
              bpurpose:res.data.bpurpose,
              purposeCode:res.data.purposeCode,
              accNumber:res.data.accNumber,
              outname:res.data.outname,
              outstreet:res.data.outstreet,
              outcity:res.data.outcity,
              outaccNumber:res.data.outaccNumber,
              purpose:res.data.purpose,
        }
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    },
    changeLineAttr(){
      this.serdata.line_attr = this.serdata.line_attr.replace(/，/g,",");
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      this.userformdata.withds = this.withds;
      let res = await this.httppost('/system/setting/save', this.userformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getserinfo(val){
      let lang = val?val:'en_US';
      let res = await this.httpget('/system/setting/getser', {lang: lang});
      if (res.code === 0) {
        this.serdata = res.data?res.data:{};
        if(!this.serdata.lang && val){
          this.serdata = this.cloneData(this.defaultserdata);
          this.serdata.lang = val;
        }
      }
    },
    async setservices(){
      this.isbusy = true;
      let res = await this.httppost('/system/setting/getser', this.serdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    uploadsuccess(res) {
      if (res.code === 0) {
        if (res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN) === -1
            && res.data[0].indexOf('http://') === -1) {
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.userformdata.picture = res.data[0];
      } else {
        this.$message({type: 'error', message: res.msg});
      }
    },
    beforeupload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Upload avatar image size can\'t exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.getlist();
    this.getserinfo();
  },
  computed: {
    baseurl: {
      get: function () {
        return process.env.VUE_APP_IMG_DOMAIN;
      }
    },
    langlist: {
      get: function() {
        return this.$store.getters.getLanglist;
      }
    },
  },
}
</script>

<style lang="scss">
.tabs {
  padding: 20px 10px;
}
.sertab{
  .el-form{
    display: flex;
    flex-direction: column;
    .el-form-item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .el-form-item__content{
    width: 100%;
    margin-left: 0px !important;
    margin-right: auto;
  }
  .el-textarea__inner{
    width: 100%;
  }
}
</style>
